import { MOBILE_BREAKPOINT, colors, colorsRGB } from "@util/constants";
import { PageProps, graphql } from "gatsby";

import { Container } from "@util/standard";
import Header from "@shared/header";
import { Hero } from "@global";
import HomeContent from "@shared/homeContent";
import { Query } from "@graphql-types";
import React from "react";
import SEO from "@shared/seo";
import styled from "styled-components";

const HeroWrapper = styled.div`
  h1::first-line {
    color: ${colors.white};
  }
  h1 {
    font-family: "arbotek";
    color: ${colorsRGB.white(0.65)};
  }
  p {
    font-size: 18px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    p {
      font-size: 14px;
    }
  }
`;
interface Props extends PageProps {
  data: Query;
}

const IndexPage = ({ data: { sanityHome } }: Props) => {
  if (sanityHome == null) {
    return null;
  }

  const { hero, seo, components } = sanityHome;

  return (
    <div>
      <Header />
      <SEO seoData={seo} />
      <HeroWrapper>
        <Hero heroData={hero} />
      </HeroWrapper>
      {components && (
        <Container flexDirection="column">
          {components.map(component => {
            if (component == null) {
              return null;
            }

            return <HomeContent key={component._key} data={component} />;
          })}
        </Container>
      )}
    </div>
  );
};

export const query = graphql`
  query HomeQuery {
    sanityHome {
      ...sanityHome
    }
  }
`;

export default IndexPage;
